import PropTypes from 'prop-types';

// Utils
import { cn } from 'utils';

// Components
import Link from 'next/link';
import NavItem from '../../atoms/NavItem/NavItem';

// Styles
import styles from './Navigation.module.css';

function Navigation({ list, type, children }) {
  return (
    <nav className={cn([styles.nav, styles[type]])}>
      <ul className={styles.list}>
        {list?.length ? list.map(({ data: { name, link, exact } }) => (
          <NavItem
            active={false}
            key={name}
            href={`${link}`}
            exact={exact}
          >
            <Link
              href={`${link}`}
              passHref
              id={`${name?.toLowerCase()}_header_desktop`}
            >
              {name}
            </Link>
          </NavItem>
        )) : null}
        {children ? (
          <NavItem>
            {children}
          </NavItem>
        ) : null}
      </ul>
    </nav>
  );
}

Navigation.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
        exact: PropTypes.bool,
      }),
    }),
  ),
  type: PropTypes.oneOf(['desktop', 'tablet', 'mobile']),
  children: PropTypes.node,
};

Navigation.defaultProps = {
  list: [],
  type: 'desktop',
  children: null,
};

export default Navigation;
