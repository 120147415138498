import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

export const availablePictos = {
  loader: dynamic(() => import('../../../public/icons/circle-loader.svg')),

  // RAWS
  turn360: dynamic(() => import('../../../public/icons/360.svg')),
  arrowBack: dynamic(() => import('../../../public/icons/arrow_back.svg')),
  arrowForward: dynamic(() => import('../../../public/icons/arrow_forward.svg')),
  arrowOutward: dynamic(() => import('../../../public/icons/arrow_outward.svg')),
  arrowRight: dynamic(() => import('../../../public/icons/arrow_right.svg')),
  arrowLeft: dynamic(() => import('../../../public/icons/arrow_left.svg')),
  arrowDown: dynamic(() => import('../../../public/icons/arrow_down.svg')),
  calendarMonth: dynamic(() => import('../../../public/icons/calendar_month.svg')),
  calendarPlus: dynamic(() => import('../../../public/icons/calendar_plus.svg')),
  call: dynamic(() => import('../../../public/icons/call.svg')),
  carouselButton: dynamic(() => import('../../../public/icons/carousel-button.svg')),
  chat: dynamic(() => import('../../../public/icons/chat.svg')),
  checkBox: dynamic(() => import('../../../public/icons/check_box.svg')),
  chevronDown: dynamic(() => import('../../../public/icons/chevron-down.svg')),
  chevronLeft: dynamic(() => import('../../../public/icons/chevron-left.svg')),
  chevronRight: dynamic(() => import('../../../public/icons/chevron-right.svg')),
  chevronUp: dynamic(() => import('../../../public/icons/chevron-up.svg')),
  clock: dynamic(() => import('../../../public/icons/clock.svg')),
  cross: dynamic(() => import('../../../public/icons/cross.svg')),
  delete: dynamic(() => import('../../../public/icons/delete.svg')),
  directionsBike: dynamic(() => import('../../../public/icons/directions_bike.svg')),
  directionsCar: dynamic(() => import('../../../public/icons/directions_car.svg')),
  directionsSubway: dynamic(() => import('../../../public/icons/directions_subway.svg')),
  directionsWalk: dynamic(() => import('../../../public/icons/directions_walk.svg')),
  facebook: dynamic(() => import('../../../public/icons/facebook.svg')),
  file: dynamic(() => import('../../../public/icons/file.svg')),
  hourglassEmpty: dynamic(() => import('../../../public/icons/hourglass_empty.svg')),
  information: dynamic(() => import('../../../public/icons/information.svg')),
  instagram: dynamic(() => import('../../../public/icons/instagram.svg')),
  like: dynamic(() => import('../../../public/icons/like.svg')),
  linkedin: dynamic(() => import('../../../public/icons/linkedin.svg')),
  locationOn: dynamic(() => import('../../../public/icons/location_on.svg')),
  locationSearching: dynamic(() => import('../../../public/icons/location_searching.svg')),
  menuBurger: dynamic(() => import('../../../public/icons/menu-burger.svg')),
  minus: dynamic(() => import('../../../public/icons/minus.svg')),
  person: dynamic(() => import('../../../public/icons/person.svg')),
  plus: dynamic(() => import('../../../public/icons/plus.svg')),
  search: dynamic(() => import('../../../public/icons/search.svg')),
  send: dynamic(() => import('../../../public/icons/send.svg')),
  starEmpty: dynamic(() => import('../../../public/icons/star-empty.svg')),
  starFill: dynamic(() => import('../../../public/icons/star-fill.svg')),
  starHalf: dynamic(() => import('../../../public/icons/star-half.svg')),
  tag: dynamic(() => import('../../../public/icons/tag.svg')),
  tiktok: dynamic(() => import('../../../public/icons/tiktok.svg')),
  time: dynamic(() => import('../../../public/icons/time.svg')),
  twitter: dynamic(() => import('../../../public/icons/twitter.svg')),
  uploadFile: dynamic(() => import('../../../public/icons/upload_file.svg')),

  // PICTOGRAMS
  snackingBlue: dynamic(() => import('../../../public/icons/pictograms/snacking-blue.svg')),
  snackingWhite: dynamic(() => import('../../../public/icons/pictograms/snacking-white.svg')),
  petitDejeunerBlue: dynamic(() => import('../../../public/icons/pictograms/petit-dejeuner-blue.svg')),
  petitDejeunerWhite: dynamic(() => import('../../../public/icons/pictograms/petit-dejeuner-white.svg')),
  cuisineEquipeeBlue: dynamic(() => import('../../../public/icons/pictograms/cuisine-equipee-blue.svg')),
  cuisineEquipeeWhite: dynamic(() => import('../../../public/icons/pictograms/cuisine-equipee-white.svg')),
  bureauBlue: dynamic(() => import('../../../public/icons/pictograms/bureau-blue.svg')),
  bureauWhite: dynamic(() => import('../../../public/icons/pictograms/bureau-white.svg')),
  coWorkingBlue: dynamic(() => import('../../../public/icons/pictograms/co-working-blue.svg')),
  coWorkingWhite: dynamic(() => import('../../../public/icons/pictograms/co-working-white.svg')),
  litBlue: dynamic(() => import('../../../public/icons/pictograms/lit-blue.svg')),
  litWhite: dynamic(() => import('../../../public/icons/pictograms/lit-white.svg')),
  securiteBlue: dynamic(() => import('../../../public/icons/pictograms/securite-blue.svg')),
  securiteWhite: dynamic(() => import('../../../public/icons/pictograms/securite-white.svg')),
  wifiBlue: dynamic(() => import('../../../public/icons/pictograms/wifi-blue.svg')),
  wifiWhite: dynamic(() => import('../../../public/icons/pictograms/wifi-white.svg')),
  bibliothequeBlue: dynamic(() => import('../../../public/icons/pictograms/bibliotheque-blue.svg')),
  bibliothequeWhite: dynamic(() => import('../../../public/icons/pictograms/bibliotheque-white.svg')),
  accueilBlue: dynamic(() => import('../../../public/icons/pictograms/accueil-blue.svg')),
  accueilWhite: dynamic(() => import('../../../public/icons/pictograms/accueil-white.svg')),
  reprographieBlue: dynamic(() => import('../../../public/icons/pictograms/reprographie-blue.svg')),
  reprographieWhite: dynamic(() => import('../../../public/icons/pictograms/reprographie-white.svg')),
  laverieBlue: dynamic(() => import('../../../public/icons/pictograms/laverie-blue.svg')),
  laverieWhite: dynamic(() => import('../../../public/icons/pictograms/laverie-white.svg')),
  colisBlue: dynamic(() => import('../../../public/icons/pictograms/colis-blue.svg')),
  colisWhite: dynamic(() => import('../../../public/icons/pictograms/colis-white.svg')),
  parkingBlue: dynamic(() => import('../../../public/icons/pictograms/parking-blue.svg')),
  parkingWhite: dynamic(() => import('../../../public/icons/pictograms/parking-white.svg')),
  lingeBlue: dynamic(() => import('../../../public/icons/pictograms/linge-blue.svg')),
  lingeWhite: dynamic(() => import('../../../public/icons/pictograms/linge-white.svg')),
  bookBlue: dynamic(() => import('../../../public/icons/pictograms/book-blue.svg')),
  bookWhite: dynamic(() => import('../../../public/icons/pictograms/book-white.svg')),
  salleDeBainBlue: dynamic(() => import('../../../public/icons/pictograms/salle-de-bain-blue.svg')),
  salleDeBainWhite: dynamic(() => import('../../../public/icons/pictograms/salle-de-bain-white.svg')),
  garageVeloBlue: dynamic(() => import('../../../public/icons/pictograms/garage-velo-blue.svg')),
  garageVeloWhite: dynamic(() => import('../../../public/icons/pictograms/garage-velo-white.svg')),
  menageBlue: dynamic(() => import('../../../public/icons/pictograms/menage-blue.svg')),
  menageWhite: dynamic(() => import('../../../public/icons/pictograms/menage-white.svg')),
  conciergerieBlue: dynamic(() => import('../../../public/icons/pictograms/conciergerie-blue.svg')),
  conciergerieWhite: dynamic(() => import('../../../public/icons/pictograms/conciergerie-white.svg')),
  penderieBlue: dynamic(() => import('../../../public/icons/pictograms/penderie-blue.svg')),
  penderieWhite: dynamic(() => import('../../../public/icons/pictograms/penderie-white.svg')),
  calendrierBlue: dynamic(() => import('../../../public/icons/pictograms/calendrier-blue.svg')),
  calendrierWhite: dynamic(() => import('../../../public/icons/pictograms/calendrier-white.svg')),
  documentBlue: dynamic(() => import('../../../public/icons/pictograms/document-blue.svg')),
  documentWhite: dynamic(() => import('../../../public/icons/pictograms/document-white.svg')),
  sonBlue: dynamic(() => import('../../../public/icons/pictograms/son-blue.svg')),
  sonWhite: dynamic(() => import('../../../public/icons/pictograms/son-white.svg')),
  pinLocatorBlue: dynamic(() => import('../../../public/icons/pictograms/pin-locator-blue.svg')),
  pinLocatorWhite: dynamic(() => import('../../../public/icons/pictograms/pin-locator-white.svg')),
  insonorisationBlue: dynamic(() => import('../../../public/icons/pictograms/insonorisation-blue.svg')),
  insonorisationWhite: dynamic(() => import('../../../public/icons/pictograms/insonorisation-white.svg')),
};
export function Picto({ icon, ...props }) {
  if (availablePictos[icon]) {
    try {
      const IconComponent = availablePictos[icon];
      return IconComponent ? React.createElement(IconComponent, props) : null;
    } catch (error) {
      console.error(`"${icon}":`, error);
    }
  }

  return null;
}

Picto.propTypes = {
  icon: PropTypes.oneOf(Object.keys(availablePictos)),
};

Picto.defaultProps = {
  icon: null,
};
